import React from 'react';
import { Button, Box } from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import "./Home.css";

function Home({ textLoaded, setTextLoaded, addressAPI, callbackStarted, callbackTextYourChoice,
    callbackTextFAQ, callbackTextTCsTitles, callbackTextTCsContent,
    colourActive, colourDisabledDark }) {

    const history = useHistory();



    const handleStartClick = () => {    
        callbackStarted(true)
        history.push("about you")
    };

    const handleFAQClick = () => {
        history.push("faq")
    };

    const handleTandCClick = () => {
        history.push("terms and conditions")
    };

    return (
        <div className="home__body">
            <div className="home__container">
                <div className="home__text__container">
                    <p className="home__text">
                    The Firefighters' Retirement Calculator is designed to help illustrate the projected benefits members will receive at different retirement ages from both the legacy and reformed Firefighters' Pension Schemes in Wales.
                    </p>  
                    <p className="home__text">
                    This calculator is for guidance purposes only and the outputs provided are an estimate only. It is not intended to provide you with financial advice. If you require financial advice, you should contact an independent financial adviser. 
                    </p>                
                    <p className="home__text"><strong>Legacy Schemes</strong></p>

                    <p className="home__text">
                    Firefighters who started their Firefighters' service before 6 April 2006 will have joined the Firefighters' Pension Scheme (Wales) 1992 (1992 Scheme) unless they opted not to do so. The New Firefighters' Pension Scheme (Wales) 2007 (referred to as the 2007 Scheme in this calculator) came into effect for new entrants from 6 April 2006. Any retained firefighters who were employed between 1 July 2000 and 5 April 2006, and made an election to pay pension contributions, will be special members of the Firefighters Pension Scheme (Wales) 2007 (referred to as the 2007 Special Scheme in this calculator).           
                    </p>
                    <p className="home__text">
                    The 1992, 2007 and 2007 Special Schemes are ‘final salary’ schemes, which means that pensions are calculated as a proportion of final pensionable pay. This is generally pay in the last year of service as a member of the scheme. The pension also depends on reckonable service, which for most firefighters will be the length of service in the Welsh Fire and Rescue Service over which they have paid pension contributions, with appropriate adjustments for part-time service. Pensionable service in this tool refers to the length of service in the Welsh Fire and Rescue Service, not adjusted for part-time service.
                    </p>  
                    <p className="home__text">
                     The 1992, 2007 and 2007 Special Schemes are referred to as the “legacy” schemes throughout this calculator. Further details about the legacy schemes can be found in the scheme guides.
                    </p> 

                    <p className="home__text"><strong>Reformed Scheme</strong></p>

                    <p className="home__text">
                    The Firefighters' Pension Scheme (Wales) 2015 (2015 Scheme) came into effect for new entrants on 1 April 2015. Some members of the legacy schemes had ‘transitional protection’ that meant they stayed in their legacy scheme, while others moved to the 2015 Scheme on or after 1 April 2015. However, it was announced in February 2021 that most members of the legacy schemes will receive a ‘deferred choice’ of whether to receive legacy or 2015 Scheme benefits for the period 1 April 2015 to 31 March 2022. See the <Link to="/faq">FAQ section</Link> for information on who is eligible and why they have this choice. 
                    </p>
                    <p className="home__text">
                    From 1 April 2022 all firefighters who continue in service will do so as members of the 2015 Scheme, regardless of age. The 2015 scheme is a Career Average Revalued Earnings (CARE) pension scheme. This means that for each year as an active member firefighters will earn a fraction (1/61.4) of the salary for that year as earned pension and this will be revalued for each subsequent year until retirement. Further details about the 2015 Scheme can be found in the 2015 Scheme guide.
                    </p>

                    <p className="home__text"><strong>Illustrations provided in this calculator</strong></p>

                    <p className="home__text">
                    The illustrations in this calculator draw out estimates of the pension and lump sum that may be available at various scheme milestones where relevant. 
                    </p>
                    <p className="home__text">
                    You are also able to see an estimate of your projected deferred benefits if you leave the scheme prior to being eligible for retirement.
                    </p>
                    <p className="home__text">
                    At retirement some members will be able to choose between receiving legacy or reformed benefits for their pensionable service between 1 April 2015 and 31 March 2022. This model is designed to help illustrate the differences in benefits members may receive depending on the choice they make. 
                    </p>
                    <p className="home__text">
                    The following information will be provided in the relevant results sections:
                    <ul>
                        <li>"Your Summary” provides a simple illustration of benefits at different retirement ages with a choice on whether legacy or reformed benefits are accrued for 2015-22.</li>
                        <li>"Your Choice” – “Comparison Chart” provides a more detailed view of the above comparison, illustrating how your pension under each retirement age varies over time (again, with a choice on whether legacy or reformed benefits are accrued for 2015-22).</li>
                        <li>"Your Choice” – Individual scenario charts (e.g. “Retirement at age 55”) – provide a detailed comparison of benefits under both options – legacy or reformed benefits accrued during 2015-22.</li>
                    </ul>
                    </p>
                    <p className="home__text">
                    If you require help with understanding the illustrations in this calculator then please refer to the <Link to="/faq">FAQ section</Link> or the scheme guides. If you have any further questions, please contact your individual Fire and Rescue Service (FRS).
                    </p>
                    <p className="home__text">
                    The estimated pension and lump sum amounts at retirement have been estimated using a given set of assumptions. Using different assumptions could produce materially different results. The key assumptions used are future inflation and salary increases.  
                    </p>

                    <p className="home__text"><strong>How to use this calculator </strong></p>

                    <p className="home__text">
                    Please click “Start” at the bottom of this page to fill in the “About You” section. 
                    </p>
                    <p className="home__text">
                    Information including the date you first joined the scheme and pensionable earnings is required for this section. You should ensure that accurate information is entered.    
                    </p>
                    <p className="home__text">
                     If you require help with filling in the "About You” section, please refer to your most recent annual benefit statement. 
                    </p>
                    <p className="home__text">
                    By using this calculator you agree you have read and understood the Terms & Conditions as set out in the <Link to="/terms and conditions">'T&Cs' tab</Link>.
                    </p>

                    <p className="home__text"><strong>Getting further help </strong></p>

                    <p className="home__text">
                    If you have any issues with the calculator not working as expected, please refer to the <Link to="/faq">FAQ section</Link>.
                    </p>
                    
                    <p className="home__text">
                    This calculator is designed and configured to be used on a desktop computer and so use via a mobile phone or other device may not provide an optimal user experience.
                    </p>

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button variant="outlined" sx={{ mr: 1 }} style={{color:colourDisabledDark,border:colourDisabledDark,borderStyle:'solid',borderWidth:'1px'}} onClick={handleFAQClick}> Go to FAQ </Button>
                        <Button variant="outlined" sx={{ mr: 1 }} style={{color:colourDisabledDark,border:colourDisabledDark,borderStyle:'solid',borderWidth:'1px'}} onClick={handleTandCClick}> Go to T&Cs </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button variant="outlined" style={{color:colourActive,border:colourActive,borderStyle:'solid',borderWidth:'1px'}} onClick={handleStartClick}>Start</Button>
                    </Box>
                    <br></br>

                </div>

            </div>
        </div>
    )
}

export default Home
